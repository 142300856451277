<template>
  <div class="mx-3">
    <v-row align="center" gutters justify="space-between">
      <v-col cols="auto">
        <GoBack :route="{ name: 'classes' }" />
        <p class="header-text">
          {{ batchData.batch.name }}
        </p>
        <p class="lightCarbon--text font-weight-regular caption">
          {{ batchData.batch.number_of_users }} students
        </p>
      </v-col>
    </v-row>
    <v-divider class="mt-2 mb-4" />

    <v-row dense>
      <v-col sm="auto">
        <v-btn
          class="px-10"
          color="primary lighten-1"
          min-width="250px"
          outlined
          :to="{
            name: 'StudentList',
          }"
        >
          Students
        </v-btn>
      </v-col>
      <v-col sm="auto">
        <v-btn
          class="px-10"
          color="primary lighten-1"
          min-width="250px"
          outlined
          :to="{
            name: 'UpcomingClasses',
          }"
        >
          Upcoming Classes
        </v-btn>
      </v-col>
      <v-col sm="auto">
        <v-btn
          class="px-10"
          color="primary lighten-1"
          min-width="250px"
          outlined
          :to="{
            name: 'PreviousClasses',
          }"
        >
          Previous Classes
        </v-btn>
      </v-col>
    </v-row>
    <router-view />
  </div>
</template>

<script>
import urls from "@/utils/urls"
import GoBack from "@/components/shared/GoBack.vue"

export default {
  name: "ViewBatch",

  components: { GoBack },

  data: () => ({
    batchId: null,

    batchData: {
      batch: {
        name: "Batch",
        number_of_users: 0,
      },
    },
  }),

  methods: {
    async getBatchData() {
      try {
        const res = await this.$http.get(
          urls.classes.getStudentsList(this.batchId)
        )
        const { name, number_of_users } = res.data.data.data.batch
        this.batchData = {
          batch: {
            name: name,
            number_of_users: number_of_users,
          },
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
