var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-3"},[_c('v-row',{attrs:{"align":"center","gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('GoBack',{attrs:{"route":{ name: 'classes' }}}),_c('p',{staticClass:"header-text"},[_vm._v(" "+_vm._s(_vm.batchData.batch.name)+" ")]),_c('p',{staticClass:"lightCarbon--text font-weight-regular caption"},[_vm._v(" "+_vm._s(_vm.batchData.batch.number_of_users)+" students ")])],1)],1),_c('v-divider',{staticClass:"mt-2 mb-4"}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":"auto"}},[_c('v-btn',{staticClass:"px-10",attrs:{"color":"primary lighten-1","min-width":"250px","outlined":"","to":{
          name: 'StudentList',
        }}},[_vm._v(" Students ")])],1),_c('v-col',{attrs:{"sm":"auto"}},[_c('v-btn',{staticClass:"px-10",attrs:{"color":"primary lighten-1","min-width":"250px","outlined":"","to":{
          name: 'UpcomingClasses',
        }}},[_vm._v(" Upcoming Classes ")])],1),_c('v-col',{attrs:{"sm":"auto"}},[_c('v-btn',{staticClass:"px-10",attrs:{"color":"primary lighten-1","min-width":"250px","outlined":"","to":{
          name: 'PreviousClasses',
        }}},[_vm._v(" Previous Classes ")])],1)],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }